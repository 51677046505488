import gmail from "@/assets/gmail.svg";
import { EMAIL_MAXLEN, STAFFCD_MAXLEN } from "@/utils/config.ts";
import { UserOutlined } from "@ant-design/icons";
import { Form, Input, Modal, Spin, message } from "antd";
import { FC, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./index.module.less";
import { forgetPasswordJapan } from "./service";

type ForgetModalProps = {
  handleClose: (flag: boolean,forgetModal?:string) => void;
};

const ForgetModal: FC<ForgetModalProps> = ({ handleClose }) => {
  const [t] = useTranslation();
  const [loading, setLoading] = useState(false);
  const [forgetForm] = Form.useForm();
  const staffCDInputRef = useRef<any>(null);
  const staffCDInputRefTimeId = useRef<NodeJS.Timeout | null>(null)
  const handleSubmit = async () => {
    try {
      setLoading(true);
      const validateRes = await forgetForm.validateFields();
      if (validateRes) {
        const values = forgetForm.getFieldsValue();
        const data: API.ForgetPasswordReq = {
          staffCD: values.staffCD?.trim(),
          mailAddress: values.mailAddress?.trim(),
        };
        const res = await forgetPasswordJapan(data);
        if (Number(res?.code) > 0) {
          message.success(t("Common_message_mailSendSuccess"));
          handleClose(false);
        }
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    staffCDInputRefTimeId.current = setTimeout(() => {
      staffCDInputRef.current.focus();
    }, 500);
    return () => clearTimeout(staffCDInputRef.current);
  },[])

  return (
    <Modal
      title={t("LoginPage_forgetPassword_title")}
      open={true}
      mask
      maskClosable={false}
      keyboard={false}
      destroyOnClose
      okText={t("LoginPage_snedMail_text")}
      cancelText={t("Common_cancelBtn_text")}
      closable={false}
      onOk={handleSubmit}
      width={600}
      okButtonProps={{
        className: "HJX_Button",
        loading,
      }}
      onCancel={() => handleClose(false,'forgetModal')}
      cancelButtonProps={{
        className: "HJX_Button",
        loading,
        style: { backgroundColor: "white", color: "black" },
      }}
    >
      <div className={styles.ForgetModal}>
        <Spin spinning={loading}>
          <div className={styles.tips}>
            <div>{t("LoginPage_forgetPassword_tips1_1")}</div>
            <div>{t("LoginPage_forgetPassword_tips2")}</div>
          </div>
          <Form form={forgetForm} layout="horizontal" colon={false} labelAlign="right" labelCol={{ span: 6 }} wrapperCol={{ span: 18 }}>
            <Form.Item
              name="staffCD"
              label={t("LoginPage_staffCD_text")}
              rules={[
                {
                  required: true,
                  message: t("LoginPage_staffCD_error_empty"),
                },
                {
                  whitespace: true,
                  message: t("LoginPage_staffCD_error_emptyString"),
                },
              ]}
            >
              <Input
                ref={staffCDInputRef}
                className="form__input"
                prefix={<UserOutlined style={{ marginRight: 10 }} />}
                maxLength={STAFFCD_MAXLEN}
                placeholder={t("LoginPage_staffCD_placeholder")}
              />
            </Form.Item>
            <Form.Item
              name="mailAddress"
              label={t("LoginPage_mailAddress_text")}
              rules={[
                {
                  required: true,
                  message: t("LoginPage_mailAddress_error_empty"),
                },
                {
                  whitespace: true,
                  message: t("LoginPage_mailAddress_error_emptyString"),
                },
                {
                  type: "email",
                  message: t("LoginPage_mailAddress_error_format"),
                },
              ]}
            >
              <Input
                className="form__input"
                prefix={<img src={gmail} width={14} style={{ marginRight: 8 }} />}
                placeholder={t("LoginPage_mailAddress_placeholder")}
                maxLength={EMAIL_MAXLEN}
              />
            </Form.Item>
          </Form>
        </Spin>
      </div>
    </Modal>
  );
};
export default ForgetModal;
